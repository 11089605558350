import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import SEO from "../../../components/seo"
import PageWrapper from "../../../components/PageWrapper"
import { Container, Col, Row } from "react-bootstrap"
import { useContent } from "../../../hooks/useContent"
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect"
import PageTitle from "../../../components/Core/PageTitle"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import FaqSection from "../../../components/Sections/FaqSection"

const Content = styled.div`

    & h2 {
        line-height: 1 !important;
    }

`

const headerConfig = {
    paddingBottom: 10,
    variant: 'minimal'
};

const InfoPortalPrinciples = () => {
    
    const content = useContent()
    
    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title={content.get('what-you-need-to-know-principles-page-title', true)} />
                    <Content className="mb-20">
                        <Container>
                            <Breadcrumb className="mt-20">
                                <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item href="/what-you-need-to-know">
                                    <Link to="/what-you-need-to-know">{content.get('what-you-need-to-know-page-title', true)}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {content.get('what-you-need-to-know-principles-page-title', true)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                        <PageTitle 
                            align="left"
                            title={ content.get('what-you-need-to-know-principles-page-title', true) }
                        />
                        <div class="inner-content">
                            <FaqSection>
                                <Row className="justify-content-between">
                                    { [ 'section-principles-first',
                                        'section-principles-second',
                                        'section-principles-third',
                                        'section-principles-fourth'].map((item) => (
                                            <Col className="col-12 col-md-5 mb-10">
                                                { content.get(item, false) }
                                            </Col>
                                        )) }
                                </Row>
                            </FaqSection>
                        </div>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default InfoPortalPrinciples