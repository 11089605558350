import React, { Children } from "react";
import { Container, Row, Col } from "react-bootstrap";

const FaqSection = ({title=null, id='', children}) => {

    return (
        <section className="bg-default" id={id}>
            <Container className="container">
                <Row className="row justify-content-between">
                    {title && 
                    (<Col
                            className="col-xl-4 col-12 pr-xl-15"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                        >
                            <div className="">
                                <h3 className="font-size-9 font-weight-bold mb-7 mb-lg-13">
                                    { title }
                                </h3>
                            </div>
                        </Col>)
                    }
                    <Col
                        className="col-xl-7 col-12"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                    >
                        <div className="faq-section-content pt-lg-4 pt-6">
                            { children }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FaqSection